<template>
  <div
    class="mbom-impact-machine px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/TestEquipment/ImpactFull-min.jpg"
          width="250"
          height="1098"
          alt=""
          title="MBOM Impact Machine"
          class="img-responsive m-auto"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="SMS MBOM Impact Machine" size="large" />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS™) manufactures an Impact Test
            Machine for clients who require in-house testing capability to
            determine energetic material response to impact energy
            stimulus.&nbsp; The SMS Impact machine is capable of running the
            Modified Bureau of Mines (MBOM), Bureau of Explosives (BOE), and
            Type 12 impact tests.&nbsp; These are the industries core impact
            sensitivity tests for producing energetic material sensitivity data.
          </p>
          <p>
            The SMS™ Impact machine has been modernized to use a Logic
            Controller and a linear drive unit to adjust the drop height,
            integrate safety features, and minimize operator error through the
            use of touch screen operations.&nbsp; The logic controls allow for
            engineered safety features to be implemented as well as reducing
            operator dependent variables for increased result reliability and
            improved operator ergonomics.
          </p>
          <p>
            Reaction of the material (go vs. no-go) is determined by operator
            observation, detection of decomposition by a gas analyzer, or
            utilizing the SMS developed
            <router-link
              class="link"
              title="GoDetect-ESD"
              to="/test-equipment/godetect"
              >GoDetect™</router-link
            >
            high speed video capture system.&nbsp; The MBOM Impact test is
            capable of producing sensitivity data in engineering units for
            direct correlation to in-process energy. The sensitivity data is
            used to determine critical safety parameters related to
            manufacturing and handling of the materials being tested.
          </p>
          <p>
            The MBOM Impact machine produced by SMS has been adopted by both the
            UN and the ET Users’ Group (Explosive Testing Users’ Group,
            www.etusersgroup.org) due to the following:
          </p>
          <ul>
            <li>
              Compliance to established industry standards for test methodology
            </li>
            <li>Applicability to historical data</li>
            <li>
              High level of fabrication controls (i.e. flatness, hardness,
              surface finish)
            </li>
            <li>
              Capable of producing data in engineering units for in-process
              energy comparison
            </li>
            <li>
              Standardized operating procedures for controlling test variables
            </li>
            <li>Equipment calibration</li>
            <li>
              Verified and repeatable test results through a lab validation
              process
            </li>
          </ul>
          <p>
            For the SMS produced MBOM Impact Machine technical parameters
            <a
              class="link"
              href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2014-SMS-Impact-Machine-Standard-Features_B.pdf?alt=media&token=4cae125f-82e4-4152-9baf-6d0dad3599f1"
              >click here</a
            >.
          </p>
        </Paragraph>
        <a
          class="heading-link"
          style="float:left;"
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2014-SMS-Impact-Machine-Standard-Features_B.pdf?alt=media&token=4cae125f-82e4-4152-9baf-6d0dad3599f1"
          target="_blank"
        >
          <div class="h-link">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="book"
              class="svg-inline--fa fa-book fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
              ></path>
            </svg>
            <h2
              class="content-box-heading fusion-responsive-typography-calculated"
              style="display:inline; font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              SMS MBOM Impact Machine Technical Specifications
            </h2>
          </div></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "SMS Impact Test Machine",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "The SMS Impact test machine can perform the Modified Bureau of Mines (MBOM), Bureau of Explosives (BOE), and Type 12 impact tests. It has been adopted by the UN and ET User's Group."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.mbom-impact-machine {
  .link {
    color: #aa222c;
  }
  .h-link:hover {
    color: #aa222c;
    .fa-book {
      color: #aa222c;
    }
  }
  .fa-book {
    color: black;
    width: 18px;
    display: inline;
  }
  .link {
    color: #aa222c;
  }
}
</style>
